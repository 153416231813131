import { Injectable } from '@angular/core';
import {Plugins, Capacitor,  FilesystemDirectory} from '@capacitor/core';

const { Filesystem } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class UtilService {
fileName: string;
imagePathCapacitor: string;

    base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);

        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }


 fileWrite(image: any, nomeFile: string ) {
    try {
      Filesystem.writeFile({
        path: nomeFile,
        data: image,
        directory: FilesystemDirectory.Data,
      //  encoding: FilesystemEncoding.UTF8
      });

    
    } catch (e) {
      console.error('Unable to write file', e);
     
    }

  }


  async fileRead(image: any, nomeFile: string) {
    const contents = await Filesystem.getUri({
      path: nomeFile,
      directory: FilesystemDirectory.Data,
      // encoding: FilesystemEncoding.UTF8

    
    }); 
    this.fileWrite(image, nomeFile);
    this.imagePathCapacitor = Capacitor.convertFileSrc(contents.uri);
    console.log(this.imagePathCapacitor);
    return this.imagePathCapacitor;
  }

}

