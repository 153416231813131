import { Injectable } from '@angular/core';


import { BehaviorSubject, throwError } from 'rxjs';
import { take, map, tap, catchError,  } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse} from '@angular/common/http';

import { Condominio, Receita } from '../../places/condominio.model';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Morador } from './moradores.model';
import { Conta } from 'src/app/contas/contas/contas.model';






@Injectable({
  providedIn: 'root'
})
export class MoradoresService {


private _condominio = new BehaviorSubject<Condominio[]>([]);
private _moradores = new BehaviorSubject<Morador[]>([]);
private _moradoresPendentes = new BehaviorSubject<Morador[]>([]);
private _moradoresContas = new BehaviorSubject<Conta[]>([]);
private _moradoresContasAnteriores = new BehaviorSubject<Conta[]>([]);
private morador: Morador;
private location: string;


get getLocation() {
  return this.location;
}

g;

get condominio() {
  return this._condominio.asObservable();
}

get moradores() {
  return this._moradores.asObservable();
}

get moradoresContas() {
  return this._moradoresContas.asObservable();
}

get moradoresContasAnteriores() {
  return this._moradoresContasAnteriores.asObservable();
}

get moradoresPendentes() {
  return this._moradoresPendentes.asObservable();
}


constructor(private authService: AuthService, private http: HttpClient) { }


  m;


getCondominio(id: number) {
  return  this.http.get<Condominio>(`${environment.baseUrl}/condominios/${id}`);

    }


uploadImage(image: File, cnpj: string) {
 

 const  uploadData = new FormData();
 uploadData.set('file', image, cnpj);

 return this.http.post(
  `${environment.baseUrl}/clientes/picture`, uploadData,
  { observe: 'response',
    responseType: 'text'
 });

}

 findMoradorPorCondominio(id: number) {
  return  this.http.get<Morador[]>(`${environment.baseUrl}/clientes/condominio/${id}`).pipe(take(1), tap(moradores => {
        this._moradores.next(moradores);

   }
   ));
  }

  findMoradorPorId(id: number) {
    return  this.http.get<Morador>(`${environment.baseUrl}/clientes/${id}`).pipe(take(1), tap(morador => {
          this.morador = morador;
  
     }
     ));
    }





findCnpjExiste(cnpj: string) {
  return  this.http.get(`${environment.baseUrl}/clientes/cnpjexiste/${cnpj}`);

}


findValidaCpf(cpf: string) {
  return  this.http.get(`${environment.baseUrl}/clientes/validacpf/${cpf}`);

}

findMedidorExiste(medidor: string) {
  return  this.http.get(`${environment.baseUrl}/clientes/medidorexiste/${medidor}`);

}

findContasMoradorPorCondominio(condominioId: number) {
  return  this.http.get<Conta[]>(`${environment.baseUrl}/contas/moradores/leitura/${condominioId}`).pipe(take(1), tap(moradoresContas => {
    this._moradoresContas.next(moradoresContas);

}));

}

findContasAnterioresMoradorPorCondominio(condominioId: number) {
  return  this.http.get<Conta[]>(`${environment.baseUrl}/contas/moradores/leitura/anterior/${condominioId}`).pipe(take(1),
  tap(moradoresContas => {
  this._moradoresContasAnteriores.next(moradoresContas);

}));

}

findMoradoresPendentes(condominioId: number) {
  return  this.http.get<Morador[]>(`${environment.baseUrl}/contas/pendentes/${condominioId}`).pipe(take(1), tap(moradores => {
    this._moradoresPendentes.next(moradores);

}));
}

findMoradores(condominioId: number) {
  return  this.http.get<Morador[]>(`${environment.baseUrl}/clientes/condominio/${condominioId}`).pipe(take(1), tap(moradores => {
    this._moradoresPendentes.next(moradores);

}));
}

addMorador(morador: Morador) {
 return this.http
   .post(
     `${environment.baseUrl}/clientes`, morador,
     { observe: 'response',
       responseType: 'json'
    }
   );
  }
  




editMorador(morador: Morador) {
  return this.http
    .put(
      `${environment.baseUrl}/clientes`, morador,
      { observe: 'response',
        responseType: 'json'
     }
    ).pipe(catchError(this.errorHandler));


 }

 errorHandler(error: HttpErrorResponse) {
  return throwError(error.message || 'Erro no Formulário');
}

deleteMorador(id: number) {

  return this.http.delete(`${environment.baseUrl}/clientes/${id}`);
  }


findByUserEmail(email: string) {
 
  return  this.http.get<Morador[]>(`${environment.baseUrl}/clientes/user/${email}`);

}

findByEmail(email: string) {
 
  return  this.http.get<Morador>(`${environment.baseUrl}/clientes/email`, { params: {value: email}}).pipe(catchError(this.errorHandler));;


}

pdfPrintLabel(condominioId: number) {


  
  return this.http
    .post(
      `${environment.baseUrl}/relatorio/label/${condominioId}`, null,
      {
        responseType:  'arraybuffer',
       // headers : headers
    
     });
    }
}
