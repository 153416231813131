import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private  jwtHelper: JwtHelperService = new JwtHelperService();
  constructor(private http: HttpClient, private authService: AuthService) { }


  uploadCliente(fileToUpload: File) {
    const endpoint = `${environment.baseUrl}/util/uploadCliente`;
    const uploadData: FormData = new FormData();


    uploadData.append('file', fileToUpload, fileToUpload.name);


    return this.http.post(endpoint, uploadData,
      { observe: 'response',
        responseType: 'text'
     });
}

uploadContaCliente(fileToUpload: File) {
  const endpoint = `${environment.baseUrl}/util/uploadContaCliente`;
  const uploadData: FormData = new FormData();


  uploadData.append('file', fileToUpload, fileToUpload.name);


  return this.http.post(endpoint, uploadData,
    { observe: 'response',
      responseType: 'text'
   });
}

push(condominioId: number) {
  const tok = this.authService.getLocalUser()._token;

  const push =  this.jwtHelper.decodeToken(tok).pushAndroid;



  const data = {
  notification: {
    title: 'Gr8',
    body: 'As fotos do conndomio' + condominioId + 'foram carregadas!! ',
    icon: 'fcm_push_icon',
    color: '#FF6600',
    sound: 'default'
  },
    // tslint:disable-next-line: max-line-length
    to: push


};

  const endpoint = `https://fcm.googleapis.com/fcm/send`;

  const httpOptions = {
    // tslint:disable-next-line: max-line-length
    headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer AAAAHmW90nk:APA91bGyUwaiuUcLUxsJiGYQV4ClVrQmBrNArrYbW-IPWEshkT6D1icOUM1wUlsWGl_5ZUmcsxh94r7IdQu1qPxmblhqaoXJFSEojXFvtdzWFaHnwAAPxpqoRBDRvH-ELkkASFoSbu8O' })
  };



  return this.http.post(endpoint, data,
    httpOptions);



}

}
