export const environment = {
  production: false,
  googleMapsAPIKey: 'AIzaSyC5u2nlByUrNzOcUsgjNgZ4oClr4qdf0Tc',
  //firebaseApi: 'AIzaSyCksD3WqPH-BhDobdZ-yF8B01jBsxomGjc',
  baseUrl: 'https://gaws.gr8medicoes.com.br:8443',
 //baseUrl: 'https://gr8-i6tir7isnq-rj.a.run.app/',
 //  baseUrl: 'https://localhost:8443',
  //  baseUrl: 'https://localhost:8080',
 localUser: 'localUser',
  firebaseStorageInit: 'https://firebasestorage.googleapis.com/v0/b/gr8med-5a3d3.appspot.com/o/images%2F128x128-',
  firebaseStorageEnd: '?alt=media&token=7c97d0c2-7903-4e46-afb0-bd3b92ae0ce4',
  bucketAws: 'https://proeximage.s3.sa-east-1.amazonaws.com/',

};
