import { Injectable } from '@angular/core';


import { BehaviorSubject } from 'rxjs';
import { take, map, tap,  } from 'rxjs/operators';
import { HttpClient} from '@angular/common/http';

import { Condominio, Receita } from '../../places/condominio.model';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Morador } from '../../moradores/moradores/moradores.model';







@Injectable({
  providedIn: 'root'
})
export class UsuarioService {



private _moradores = new BehaviorSubject<Morador[]>([]);
private _moradoresPendentes = new BehaviorSubject<Morador[]>([]);
private morador: Morador;



g
get moradores() {
  return this._moradores.asObservable();
}

get moradoresPendentes() {
  return this._moradoresPendentes.asObservable();
}


constructor(private authService: AuthService, private http: HttpClient) { }


  





 findUsuario() {
  return  this.http.get<Morador[]>(`${environment.baseUrl}/clientes/usuarios/`).pipe(take(1), tap(moradores => {
        this._moradores.next(moradores);

   }
   ));
  }

  findUsuarioPorId(id: number) {
    return  this.http.get<Morador>(`${environment.baseUrl}/clientes/${id}`).pipe(take(1), tap(morador => {
          this.morador = morador;
  
     }
     ));
    }





findCnpjExiste(cnpj: string) {
  return  this.http.get(`${environment.baseUrl}/clientes/cnpjexiste/${cnpj}`);

}


findValidaCpf(cpf: string) {
  return  this.http.get(`${environment.baseUrl}/clientes/validacpf/${cpf}`);

}



findByUserEmail(email: string) {
  return  this.http.get(`${environment.baseUrl}/clientes/user/${email}`);

}






addMorador(morador: Morador) {
 return this.http
   .post(
     `${environment.baseUrl}/clientes`, morador,
     { observe: 'response',
       responseType: 'json'
    }
   );
  }
  




editMorador(morador: Morador) {
  return this.http
    .put(
      `${environment.baseUrl}/clientes`, morador,
      { observe: 'response',
        responseType: 'json'
     }
    );


 }

deleteMorador(id: number) {
  console.log(id);
  return this.http.delete(`${environment.baseUrl}/clientes/${id}`);
  }
}
